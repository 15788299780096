
import { computed, defineComponent, ref } from 'vue'
import InputField from "@/components/utils/input/InputField.vue";
import TextAreaField from "@/components/utils/input/TextAreaField.vue";
import { useContactsStore } from "@/store/contacts/ContactsStore";
import { AddContactInput } from "@/shared/types";

export default defineComponent({
  name: "DashboardHomeContactForm",
  components: { TextAreaField, InputField },
  setup() {
    const contactsStore = useContactsStore()

    const topic = ref<string | null>(null)
    const content = ref<string | null>(null)

    const input = computed<AddContactInput | undefined>(() => {
      if (!topic.value || !content.value) return undefined
      return {
        topic: topic.value,
        content: content.value
      }
    })

    const sendContact = async () => {
      if (!input.value) return
      await contactsStore.addContact(input.value)
      topic.value = null
      content.value = null
    }
    return { topic, content, sendContact }
  }
})
