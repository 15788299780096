
import {computed, defineComponent, PropType} from "vue";
import {InputFieldType} from "@/types";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: false,
    },
    inputType: {
      type: String as PropType<InputFieldType>,
      default: InputFieldType.TEXT,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      required: false,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    notValidMessage: {
      type: String,
      required: false,
    },
    fieldId: {
      type: String,
      default: "true",
    },
    isAutocomplete: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const dynamicClasses = computed(() => {
      const classes: string[] = []
      classes.push(props.valid ? 'focus:border-blue-50' : 'focus:border-red-500')
      classes.push(props.disabled ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : 'bg-transparent')
      return classes
    })
    return {InputFieldType,dynamicClasses};
  },
});
