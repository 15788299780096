import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative flex items-center h-full" }
const _hoisted_2 = ["type", "required", "value", "disabled", "name", "autocomplete"]
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  key: 0,
  class: "mt-2 text-sm text-red-600"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("textarea", {
        rows: "3",
        type: _ctx.inputType,
        required: _ctx.required,
        value: _ctx.value,
        disabled: _ctx.disabled,
        onInput: _cache[0] || (_cache[0] = 
          (event) =>
            _ctx.$emit(
              'update:value',
              _ctx.inputType === _ctx.InputFieldType.NUMBER
                ? +event.target.value
                : event.target.value
            )
        ),
        name: _ctx.fieldId,
        placeholder: " ",
        autocomplete: _ctx.inputType === _ctx.InputFieldType.PASSWORD ? 'new-password' : _ctx.isAutocomplete ? 'on' : 'off',
        class: _normalizeClass(["block w-full appearance-none rounded-md bg-white border border-gray-200 focus:outline-none relative z-0", _ctx.dynamicClasses])
      }, null, 42, _hoisted_2),
      _createElementVNode("label", {
        for: _ctx.fieldId,
        class: "absolute top-0 duration-300 origin-0 text-gray-500 text-sm font-normal ml-3 bg-white transform translate-y-3 rounded-sm"
      }, _toDisplayString(_ctx.label), 9, _hoisted_3)
    ]),
    (!_ctx.valid)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.notValidMessage), 1))
      : _createCommentVNode("", true)
  ]))
}