
import DashboardHomeContactForm from "@/components/forms/DashboardHomeContactForm.vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "Contact",
  components: {DashboardHomeContactForm  },
  setup() {
    return {};
  },
});
